import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

const ArnoldsGarage = () => {

  return (
    <Layout>
      <SEO title="Case Study: Arnold's Garage Door Service" />


    </Layout>
  )
}

export default ArnoldsGarage